'use strict'

import { Gesture } from '@use-gesture/vanilla'

import { addFocusedBy, removeFocusedBy } from '@design/utilities/helpers/body-focused-by'

export interface MobileBottomPanelConfig {
  rootEl?: HTMLElement
  class?: string
  onOpen?: (mobileBottomPanel: MobileBottomPanel) => void
  onClose?: (mobileBottomPanel: MobileBottomPanel) => void
  onContentSet?: (mobileBottomPanel: MobileBottomPanel) => void
  onDestroy?: (mobileBottomPanel: MobileBottomPanel) => void
  isMobile?: () => boolean
}

export default class MobileBottomPanel {
  config!: MobileBottomPanelConfig
  element!: HTMLElement
  isOpened!: boolean
  fadeEl!: HTMLElement
  handleEl!: HTMLElement | null
  wrapperEl!: HTMLElement
  closeEl!: HTMLElement | null
  contentEl!: HTMLElement
  gesture!: Gesture

  constructor(element: string | HTMLElement, config: MobileBottomPanelConfig = {}) {
    let el: HTMLElement | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector(element) as HTMLElement
    }

    if (!el || typeof el === 'string') {
      return
    }

    this.element = el

    if (!config.rootEl) {
      config.rootEl = this.element.parentNode as HTMLElement
    }

    if (!config.isMobile) {
      config.isMobile = () => true
    }

    this.config = config
    this.isOpened = false

    this.setElements()

    if (this.config.class) {
      this.element.classList.add(this.config.class)
    }

    this.initEvents()

    this.element.setAttribute('data-init', 'true')
  }

  setElements(): void {
    const wrapperEl = this.element.querySelector('.o-mobile-bottom-panel__wrapper') as HTMLElement
    const fadeEl = this.element.querySelector('.o-mobile-bottom-panel__fade') as HTMLElement
    const contentEl = this.element.querySelector('.o-mobile-bottom-panel__content') as HTMLElement

    this.wrapperEl = wrapperEl
    this.fadeEl = fadeEl
    this.contentEl = contentEl
    this.handleEl = this.element.querySelector('.o-mobile-bottom-panel__handle')
    this.closeEl = this.element.querySelector<HTMLElement>('.o-mobile-bottom-panel__heading > .o-mobile-bottom-panel__close')
  }

  getElement(): HTMLElement {
    return this.element
  }

  initEvents(): void {
    if (this.closeEl) {
      this.fadeEl.addEventListener('click', () => {
        this.close()
      })

      this.closeEl.addEventListener('click', () => {
        this.close()
      })

      document.body.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
          this.close()
        }
      })
    }

    if (this.handleEl) {
      this.gesture = new Gesture(
        this.handleEl,
        {
          onDragEnd: (state) => {
            if (state.direction[1] === 1) {
              this.toggle()
            }
          },
        },
        {
          drag: {
            axis: 'y',
          },
        }
      )
    }

    // trap focus
    this.element.addEventListener('keydown', (e) => {
      if (!this.isOpened) {
        return
      }

      const focusableEls = this.element.querySelectorAll<HTMLElement>(
        'a[href]:not([disabled]), button:not([disabled]):not([tabIndex="-1"]), input:not([disabled]):not([type="hidden"]):not([tabIndex="-1"]), select:not([disabled]):not([tabIndex="-1"]), textarea:not([disabled]):not([tabIndex="-1"])'
      )
      const firstFocusableEl = focusableEls[0]
      const lastFocusableEl = focusableEls[focusableEls.length - 1]
      const KEYCODE_TAB = 9

      if (!lastFocusableEl || !firstFocusableEl) {
        return
      }

      const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus()
          e.preventDefault()
        }
        /* tab */
      } else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus()
          e.preventDefault()
        }
      }
    })
  }

  open(): MobileBottomPanel {
    if (!this.config.isMobile?.() || this.isOpened) {
      return this
    }

    addFocusedBy('mobileBottomPanel')

    if (document.body !== this.element.parentNode) {
      document.body.append(this.element)
    }

    this.element.removeAttribute('hidden')

    requestAnimationFrame((): void => {
      this.element.setAttribute('data-open', 'true')
      this.element.focus()
      this.isOpened = true

      this.element.dispatchEvent(new CustomEvent('mobileBottomPanel.opened'))

      if (this.config.onOpen) {
        this.config.onOpen(this)
      }
    })

    return this
  }

  close(): MobileBottomPanel {
    if (!this.config.isMobile?.() || !this.isOpened) {
      return this
    }

    requestAnimationFrame((): void => {
      this.element.setAttribute('data-open', 'false')

      removeFocusedBy('mobileBottomPanel')
      this.isOpened = false

      this.element.dispatchEvent(new CustomEvent('mobileBottomPanel.closed'))

      const transitionEndFn = (): void => {
        if (this.config.rootEl !== this.element.parentNode) {
          this.config.rootEl?.append(this.element)
        }
        this.wrapperEl.removeEventListener('transitionend', transitionEndFn)

        if (this.config.onClose) {
          this.config.onClose(this)
        }
      }

      this.wrapperEl.addEventListener('transitionend', transitionEndFn)
    })

    return this
  }

  toggle(): MobileBottomPanel {
    if (this.isOpened) {
      this.close()
    } else {
      this.open()
    }

    return this
  }
}
